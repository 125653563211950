.ilpf-account-banner {
  width: 55%;
  height: 100vh;
  background: url('../../../public/resources/images/account-banner.svg');
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }

  .ilpf-account-logo {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    img {
      width: 155px;
      height: 43px;
      padding: 2.5rem;
    }
  }

  .ilpf-account-with-screen {
    display: flex;
    justify-content: center;
    img {
      width: 350px;
      height: 660px;
      padding-left: 3.125rem;
    }
  }
}
