.ilpf-map-main-section {
  flex-grow: 1;

  .map-container {
    width: 100%;
    height: 100%;
  }

  .reset-bounds-container {
    top: 80px;

    a {
      cursor: pointer;
      font-size: 1.375rem;
      line-height: normal;
    }
  }

  .map-instructions-container {
    border-radius: 3px;
    position: relative;
    width: fit-content;
    top: 2.5rem;
    left: 50%;
    transform: translate(-50%);
    z-index: 1000000;
    background-color: white;
    padding: 0.5rem 1rem;
    padding-right: 1.5rem;
    cursor: default;

    .map-instructions-close-btn {
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0.3rem;
      line-height: 0;
      align-self: flex-start;

      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
