.ilpf-login-screen {
  display: flex;

  .ilpf-login-content {
    width: 45%;
    max-height: 100vh;
    overflow-y: auto;

    @media (max-width: 768px) {
      width: 100%;
      padding: 1rem;
    }

    .ilpf-login-main-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.5rem;

      @media (max-width: 768px) {
        flex-direction: column;

        & > img:first-child {
          display: none;
        }
      }

      img {
        width: 155px;
      }

      .ilpf-login-create-account-box {
        span {
          font-size: 0.875rem;
          font-weight: 400;
        }

        a {
          font-family: 'Roboto', sans-serif !important;
          color: #065228;
          font-size: 0.875rem;
          font-weight: 500;
          margin-left: 0.25rem;
          cursor: pointer;
        }
      }
    }

    .ilpf-login-form-box {
      margin: 0 4.0625rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 768px) {
        margin: 0;
        margin-top: 2rem;
      }

      h1 {
        font-size: 1.75rem;
        font-weight: 700;
        text-align: center;
        :nth-child(2) {
          color: #019136;
        }
      }

      .ilpf-login-subtitle {
        margin-top: 0.5rem;
        text-align: center;
      }

      .ilpf-login-form {
        @media (max-width: 768px) {
          width: 100%;
        }

        form {
          padding-top: 2.5rem;
          width: 20.125rem;
          display: flex;
          flex-direction: column;

          @media (max-width: 768px) {
            width: 100%;
          }

          label {
            margin-bottom: 0.5rem;
            font-size: 1rem;
            font-weight: 400;
          }

          .error-message {
            color: red;
            font-size: 1rem;
            font-weight: 400;
          }

          .password-field {
            margin-top: 1.5rem;
          }

          .additional-links-box {
            margin-top: 0.5rem;

            a {
              display: block;
              margin-top: 1rem;
              cursor: pointer;
              font-family: 'Roboto', sans-serif !important;
              font-size: 0.875rem;
              font-weight: 500;
              color: #005885;
            }
          }

          .login-button {
            margin-top: 2.5rem;
            margin-bottom: 1.5625rem;
            padding: 0.75rem 0;
            text-transform: none;
            background-color: #065228;
          }
        }
      }
    }

    .ilpf-login-return-to-public-map-box {     
      margin: 20px auto -20px;
      text-align: center;
      
      a {
        font-family: "Roboto", sans-serif !important;
        color: #065228;
        font-size: 0.875rem;
        font-weight: 500;
        margin-left: 0.25rem;
        cursor: pointer;
      }
    }

    .about-section {
      margin: 6rem 0;
      padding: 0 4.0625rem;

      @media (max-width: 768px) {
        padding: 0 1rem;
      }

      h4 {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      p {
        margin-top: 1rem;
        font-size: 0.875rem;
      }
    }
  }
}
