.user-mgmt-table-search-container {
  .user-mgmt-table-input {
    background-color: white;
    width: 100%;
    max-width: 20.5rem;
    border-radius: 28px;

    & > div {
      border-radius: 28px;
    }

    input {
      padding: 0.875rem 1.25rem;
    }
  }
}
