.table-container {
  max-width: 100%;
  overflow-x: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  margin-top: 20px;
}

.MuiTable-root {
  width: 100%;
  border-collapse: collapse;
}

.MuiTableHead-root {
  background-color: #f5f5f5;
}

.MuiTableCell-head {
  padding: 10px;
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid #dddddd;
  color: #333;
  font-size: 12px !important;
}

.MuiTableCell-body {
  padding: 8px;
  border-bottom: 1px solid #dddddd;
  color: #555;
  font-size: 12px !important;
}

.MuiTableRow-root:hover {
  background-color: #f1f1f1;
}

.no-data {
  padding: 20px;
  text-align: center;
  color: #999;
}

.label-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  label {    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    color: #646464;
    font-size: 12px;
    margin-right: 5px;
  }

  img {
    width: 120px !important;
    height: 120px !important;
  }
}