.ilpf-login-navigation {
  padding: 2.1875rem;

  .login-button {
    height: 21px;
    cursor: pointer;
  }

  img {
    width: 21px;
    height: 21px;
    vertical-align: middle;
    padding-right: 0.9375rem;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
}
