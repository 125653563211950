.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.geo-map {
  width: 100%;
  padding: 35px 5px 10px 10px !important;
  text-align: center;
}

img {
  width: 155px;    
}

.last-grid-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.logo-digital {
  margin: -20px 0px !important;
  width: 180px !important;  
}


.ilpf-login-account {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -12px !important;
}

.link-access {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: white !important;
  box-sizing: border-box !important;
  transition: all .1s ease-in-out !important;
  margin: auto !important;
  text-decoration: none !important;
  background: #065228 !important;
  padding: .6em .9em !important;
  cursor: pointer;
}

