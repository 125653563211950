.ilpf-plot-graph {
  display: flex;
  flex-direction: row;
  background-color: #eeeff1;
  overflow: hidden;
  height: 100vh;
}

.ilpf-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.5rem;
  overflow-y: auto;

  .load-all-glebe-btn {
    width: fit-content;
    margin-top: 1rem;
    color: #065228;
    border-color: #065228;

    &:hover {
      background-color: #5779661f;
      border-color: #053e1e;
    }
  }

  .ilpf-section-graph {
    background-color: #ffff;
    border-radius: 10px;
    margin-top: 3.125rem;
    padding: 0.9375rem;
    min-height: 380px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.title {
  font-size: 1.75rem;
  color: green;
  font-weight: bold;
}

.image {
  background-color: black;
}

.bar {
  opacity: 1;
}
