.ilpf-map-private-side-box {
  padding: 1.25rem 0;
  max-width: 35%;
  min-width: 25%;
  height: 100vh;

  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 35vh;
    max-width: 100%;
    width: 100%;
  }

  .ilpf-map-private-car-search-bar {
    margin: 0 1.875rem;
    border-radius: 28px;
    border: 1px solid #d6d6d6;
    margin-bottom: 1rem;
    display: flex;
    .car-search-bar-input {
      flex: 1;
      margin-left: 1rem;
    }
    .car-search-bar-icon {
      padding: 0.575rem;
    }
  }
  .ilpf-map-private-cars-list {
    padding: 0 1.875rem;
    max-height: 100%;
    overflow-y: auto;
    list-style: none;

    .car-list-item {      
      width: 100%;
      display: block;
      text-align: left;
      padding: 0.575rem;
      margin-bottom: 1.5rem;
      border: 1px solid #d6d6d6;
      border-radius: 8px;
      text-transform: none;
      .car-list-item-farm-name {
        font-size: 1.15rem;
        color: #045228;
        margin-bottom: 0.5rem;
      }
      .car-list-item-id {
        font-size: 0.875rem;
        color: #808080;
      }
    }
    .car-list-empty {
      max-width: 28.125rem;
      padding: 0.575rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .car-list-empty-title {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
        color: #808080;
      }
      .car-list-empty-description {
        font-size: 0.875rem;
        color: #808080;
      }
    }
    .selected {
      border: 1px solid #045228;
    }
  }
}
