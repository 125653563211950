.menu-list-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: row;
  }
}

.menu-button {
  padding: 0.625rem;
  border-radius: 5px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  text-decoration: none;
  color: #444444;

  svg {
    color: #045228;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    background-color: #f0f5efa3;
  }
}

.menu-button-active {
  background-color: #dfeadc;

  svg {
    color: #009135;
  }
}
