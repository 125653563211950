.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    padding: 0 !important;
  }

  .pic {
    margin-bottom: 0.3125rem;
    span {
      color: #444444;
    }
  }

  > span {
    text-transform: capitalize;
    color: #444444;

    @media (max-width: 768px) {
      display: none;
    }
  }
}
