.private-route-container {
  display: flex;

  > :nth-child(2) {
    flex-grow: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100vh;
  }
}
