.ilpf-forgot-password-screen {
  display: flex;

  .ilpf-forgot-password-content {
    width: 45%;

    @media (max-width: 768px) {
      width: 100%;
      padding: 1rem;
    }

    .ilpf-forgot-password-main-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding: 2.5rem;

      h1 {
        font-size: 1.75rem;
        font-weight: 700;
        text-align: center;
        color: #019136;
      }

      .ilpf-forgot-password-text {
        text-align: center;
      }
    }

    .ilpf-forgot-password-form {
      margin: 0 4.0625rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 768px) {
        margin: 0;
      }

      form {
        padding-top: 2.5rem;
        width: 20.125rem;
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          width: 100%;
        }

        label {
          margin-bottom: 0.5rem;
          font-size: 1rem;
          font-weight: 400;
        }

        .error-message {
          color: red;
          font-size: 1rem;
          font-weight: 400;
        }

        .password-field {
          margin-top: 1.5rem;
        }

        .forgot-password-box {
          margin-top: 1.5rem;

          a {
            cursor: pointer;
            font-family: 'Roboto', sans-serif !important;
            font-size: 0.875rem;
            font-weight: 500;
            color: #005885;
          }
        }

        .forgot-password-button {
          margin-top: 2.5rem;
          padding: 0.75rem 0;
          text-transform: none;
          background-color: #065228;
        }
      }
    }
  }
}
