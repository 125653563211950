.ilpf-create-account-screen {
  display: flex;
  white-space: pre-line;

  .ilpf-create-account-content {
    width: 45%;

    @media (max-width: 768px) {
      width: 100%;
      padding: 1rem;
    }

    .ilpf-create-account-main-box {
      margin: 0 4.625rem;

      @media (max-width: 768px) {
        margin: 0;
      }

      h1 {
        font-size: 2.25rem;
        font-weight: 700;
        color: #045228;
        padding-top: 7.5rem;
        line-height: 1.8rem;
      }

      .ilpf-create-account-text {
        margin-top: 1rem;
        font-size: 0.9375rem;
      }

      .ilpf-create-account-button {
        padding-top: 2.5rem;
      }

      .playstore-button {
        width: 20.625rem;        
        text-transform: none;

        @media (max-width: 768px) {
          width: 100%;
        }

        span {
          padding-left: 0.625rem;
          text-align: left;
        }

        img {
          height: 25px;
          width: 20px;
        }
      }

      .apple-button {
        width: 20.625rem;
        text-transform: none;

        @media (max-width: 768px) {
          width: 100%;
        }

        span {
          padding-left: 0.625rem;
        }

        img {
          width: 18px;
        }
      }
    }
  }
}
