.bar-chart-container {
  height: 100%;
  min-width: 1400px;

  display: flex;
  flex-direction: column;

  & .chart-wrapper {
    flex-grow: 1;
  }

  & .recharts-label {
    tspan {
      text-anchor: middle;
    }
  }
}

.popup-description {
  width: 6.25rem;
  height: 6.25rem;
  background-color: black;
}

.chart-title {
  display: flex;
  flex-direction: row;
  color: green;
  font-weight: bold;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
  border-style: none none solid none;
  border-width: 1px;
  border-color: #bababa;
}
