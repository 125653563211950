.comboContainer {
    display: flex;
    flex-direction: row;
    gap: 20px; // Espaçamento entre os combos
  
    .comboWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiFormControl-root { // Esta classe é específica do Material-UI para FormControl
        min-width: 200px; // Define um tamanho mínimo para os combos
      }
    }
  }