.user-mgmt-table-container {
  max-width: 100%;
  overflow-y: auto;

  .user-mgmt-table {
    margin-top: 3.75rem;
    background-color: white;
    border: 1px solid #e2e4e8;
    border-radius: 4px;
    border-collapse: collapse;
    width: 100%;

    th {
      text-align: start;
    }

    th,
    td {
      padding: 1rem 2.25rem;
    }

    tr {
      border-bottom: 1px solid #e2e4e8;
    }

    .pagination {
      width: 100%;
    }

    .delete-user-button {
      cursor: pointer;
      background-color: transparent;
      border: none;

      svg {
        color: #b7363b;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: #902a2d;
        }
      }
    }
  }
}
