.ilpf-user-management-page {
  padding: 2.5rem;
  background-color: #eeeff1;

  h1 {
    font-weight: 700;
    font-size: 1.5rem;
    color: #045228;
  }

  .splitter {
    margin: 1.875rem 0;
    background-color: #e2e4e8;
    height: 2px;
  }
}
