.recharts-custom-tooltip {
  margin: 0px;
  padding: 0.625rem;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;

  .recharts-tooltip-label {
    margin: 0px;
  }

  .recharts-tooltip-item-list {
    padding: 0px;
    margin: 0px;

    .recharts-tooltip-item {
      display: block;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }
}

.recharts-custom-tooltip-wrapper-visible {
  visibility: visible !important;
}
