.ilpf-side-bar {
  height: 100vh;
  padding: 1.5rem 0.875rem;
  background-color: #ffff;
  border: 1px solid #e2e4e8;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: fit-content;
    padding: 0.5rem;
  }

  .side-bar-buttons-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: row;
    }
  }

  .ilpf-rede-logo {
    h1 {
      margin: 0;
    }
    img {
      width: 70px;
    }
  }
}
